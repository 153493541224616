@import "./_sass-essentials/essentials.scss";

.pt--tw-text-image {
  &.width-full-screen {
    .paragraph-inner {
      @include l-gutter($gut-default: $l-gut-sm, $gut-sm: false, $gut-md: false, $gut-lg: false);
    }
  }

  .image-text-wrap {
    @include fg-row();

    > .field--name-field-image {
      @include fg-xxs-col(12);
      @include fg-xs-col(4);
      @include fg-sm-col(5);
      @include fg-md-col(6);
    }

    > .field--name-field-text {
      @include fg-xxs-col(12);
      @include fg-xs-col(8);
      @include fg-sm-col(7);
      @include fg-md-col(6);
    }
  }

  &.img-left {
    .image-text-wrap {
      flex-direction: row-reverse;

      > .field--name-field-image {
        margin-bottom: $l-gutter-half;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &.img-right {
    .image-text-wrap {
      > .field--name-field-text {
        margin-bottom: $l-gutter-half;
      }
    }
  }

  /*
  &.bg-color_name {
    background-color: $c-brand-primary;
    color: $c-white;
    padding-top: $co-mob;
    padding-bottom: $co-mob;

    @include mq($bp-desktop-min) {
      padding-top: $co-desk;
      padding-bottom: $co-desk;
    }

    @include mq($bp-md-min) {
      padding-top: $co-lg;
      padding-bottom: $co-lg;
    }
  }
  */
}
