.pt--tw-text-image {
  /*
  &.bg-color_name {
    background-color: $c-brand-primary;
    color: $c-white;
    padding-top: $co-mob;
    padding-bottom: $co-mob;

    @include mq($bp-desktop-min) {
      padding-top: $co-desk;
      padding-bottom: $co-desk;
    }

    @include mq($bp-md-min) {
      padding-top: $co-lg;
      padding-bottom: $co-lg;
    }
  }
  */
}

.pt--tw-text-image.width-full-screen .paragraph-inner {
  padding-left: 15px;
  padding-right: 15px;
}

.pt--tw-text-image .image-text-wrap {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.pt--tw-text-image .image-text-wrap > .field--name-field-image {
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
  flex-basis: 100%;
  max-width: 100%;
}

@media screen and (min-width: 480px) {
  .pt--tw-text-image .image-text-wrap > .field--name-field-image {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 768px) {
  .pt--tw-text-image .image-text-wrap > .field--name-field-image {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
}

@media screen and (min-width: 992px) {
  .pt--tw-text-image .image-text-wrap > .field--name-field-image {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 50%;
  }
}

.pt--tw-text-image .image-text-wrap > .field--name-field-text {
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
  flex-basis: 100%;
  max-width: 100%;
}

@media screen and (min-width: 480px) {
  .pt--tw-text-image .image-text-wrap > .field--name-field-text {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
}

@media screen and (min-width: 768px) {
  .pt--tw-text-image .image-text-wrap > .field--name-field-text {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
}

@media screen and (min-width: 992px) {
  .pt--tw-text-image .image-text-wrap > .field--name-field-text {
    flex: 0 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 50%;
  }
}

.pt--tw-text-image.img-left .image-text-wrap {
  flex-direction: row-reverse;
}

.pt--tw-text-image.img-left .image-text-wrap > .field--name-field-image {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}

.pt--tw-text-image.img-right .image-text-wrap > .field--name-field-text {
  margin-bottom: 15px;
}
